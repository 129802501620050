::selection {
    background: transparent;
    color: green;
}

::-webkit-selection {
    background: transparent;
    color: green;
}

.blinking-cursor {
    display: inline-block;
    width: 3px;
    height: 1.2em;
    background-color: white;
    animation: infinite;
    margin-left: 5px;
    vertical-align: middle;
}

.gradient-text {
    background: linear-gradient(to right, #6ee7b7, #3b82f6);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    font-weight: bold;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in-text {
    animation: fadeIn 2s ease-out forwards;
}

html,
body {
    background-color: #000;
    color: #e5e7eb;
    scroll-behavior: smooth;
}